import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Form, Alert, Row, Col, Spinner, Modal, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeft, FaEdit, FaSave, FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { getSellerById, updateSeller } from '../../services/sellerService';
import { getRegionById } from '../../services/regionService';
import '../../styles.css';

const SellerDetails = () => {
    const [editableSeller, setEditableSeller] = useState({});
    const [backupSeller, setBackupSeller] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [regionName, setRegionName] = useState('');
    const [validationError, setValidationError] = useState('');
    const [showModal, setShowModal] = useState(false); // Modal visibility state
    const [imageModal, setImageModal] = useState({ show: false, currentIndex: 0 }); // Image modal state
    const location = useLocation();
    const navigate = useNavigate();

    const baseImageUrl = 'https://assets.harveststack.co/pictures/sellers/large/';


    useEffect(() => {
        const fetchSellerDetails = async () => {
            const query = new URLSearchParams(location.search);
            const sellerId = query.get('sellerId');

            if (!sellerId) {
                console.error('No sellerId found in URL');
                setLoading(false);
                return;
            }

            try {
                const sellerData = await getSellerById(sellerId);

                if (!sellerData) {
                    throw new Error('Seller data not found in the response');
                }

                setEditableSeller(sellerData);
                setBackupSeller(sellerData);

                if (sellerData.sellerRegion) {
                    const regionData = await getRegionById(sellerData.sellerRegion);
                    setRegionName(regionData?.regionName || 'Unknown Region');
                }
            } catch (error) {
                console.error('Error fetching Seller details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSellerDetails();
    }, [location]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // If it's the status field, map the dropdown value to a boolean
        if (name === 'active') {
            setEditableSeller({ ...editableSeller, [name]: value === 'true' });
        } else {
            setEditableSeller({ ...editableSeller, [name]: value });
        }

        if (name === 'collectionCostPerKilo') {
            setValidationError('');
        }
    };

    const handleEdit = () => {
        setBackupSeller(editableSeller);
        setIsEditing(true);
    };

    const handleCancel = () => {
        setEditableSeller(backupSeller);
        setIsEditing(false);
        setValidationError('');
    };

    const handleSave = async () => {
        setIsSaving(true);
        setIsSaved(false);
        setSaveError(null);

        const cost = parseFloat(editableSeller.collectionCostPerKilo);
        if (isNaN(cost) || cost < 0) {
            setValidationError('Collection cost per kilo must be a number greater or equal to 0');
            setIsSaving(false);
            return;
        }

        try {
            await updateSeller(editableSeller.sellerId, {
                sellerName: editableSeller.sellerName,
                sellerDescription: editableSeller.sellerDescription,
                shortDescription: editableSeller.shortDescription,
                collectionCostPerKilo: cost,
                active: editableSeller.active, // Boolean value from dropdown
            });
            setIsSaved(true);
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving Seller:', error);
            setSaveError(error.message);
        } finally {
            setIsSaving(false);
            setShowModal(false); // Hide the modal after saving
        }
    };

    const handleShowModal = () => {
        setShowModal(true); // Show confirmation modal
    };

    const handleCloseModal = () => {
        setShowModal(false); // Hide confirmation modal
    };

    const handleBack = () => {
        navigate('/sellers');
    };

    const renderImages = () => {
        if (!editableSeller.sellerImages || editableSeller.sellerImages.length === 0) {
            return <p>No images available for this seller.</p>;
        }

        return (
            <div className="d-flex flex-wrap">
                {editableSeller.sellerImages.map((imageName, index) => (
                    <div key={imageName} className="me-2 mb-3 text-center">
                        <Image
                            src={`${baseImageUrl}${imageName}`}
                            alt={imageName}
                            width={100}
                            rounded
                            onClick={() => openImageModal(index)}
                            style={{ cursor: 'pointer' }}
                        />
                        <div style={{ fontSize: '0.9rem', marginTop: '0.5rem' }}>{imageName}</div>
                    </div>
                ))}
            </div>
        );
    };

    const openImageModal = (index) => {
        setImageModal({ show: true, currentIndex: index });
    };

    const closeImageModal = () => {
        setImageModal({ show: false, currentIndex: 0 });
    };

    const goToPreviousImage = () => {
        setImageModal((prevState) => ({
            ...prevState,
            currentIndex:
                prevState.currentIndex === 0
                    ? editableSeller.sellerImages.length - 1
                    : prevState.currentIndex - 1,
        }));
    };

    const goToNextImage = () => {
        setImageModal((prevState) => ({
            ...prevState,
            currentIndex:
                prevState.currentIndex === editableSeller.sellerImages.length - 1
                    ? 0
                    : prevState.currentIndex + 1,
        }));
    };

    // Check if there are any changes between editableSeller and backupSeller
    const hasChanges = JSON.stringify(editableSeller) !== JSON.stringify(backupSeller);

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    const basicInformation = [
        { label: 'Seller Name', key: 'sellerName', editable: true },
        { label: 'Seller Code', key: 'sellerCode', editable: false },
        { label: 'Region', key: 'sellerRegion', value: regionName, editable: false },
        { label: 'Country', key: 'sellerCountry', editable: false },
        { label: 'State', key: 'sellerState', editable: false },
        { label: 'Collection Cost Per Kilo', key: 'collectionCostPerKilo', editable: true },
        {
            label: 'Status',
            key: 'active',
            editable: true,
            as: 'select',
            options: [
                { value: 'true', label: 'Active' },
                { value: 'false', label: 'Inactive' },
            ],
        },
    ];

    const contentInformation = [
        { label: 'Short Description', key: 'shortDescription', as: 'textarea', rows: 3, editable: true },
        { label: 'Description', key: 'sellerDescription', as: 'textarea', rows: 6, editable: true },
    ];

    const auditFields = [
        { label: 'Seller ID', key: 'sellerId', editable: false },
    ];

    const renderFields = (fields) => {
        return (
            <Row>
                {fields.map(({ label, key, value, as, rows, editable, options }) => (
                    <Col md={as ? 12 : 6} key={key} className="mb-3">
                        <Form.Group>
                            <Form.Label>{label}</Form.Label>
                            {as === 'select' && options ? (
                                <Form.Control
                                    as="select"
                                    name={key}
                                    value={editableSeller[key] ? 'true' : 'false'}
                                    onChange={handleInputChange}
                                    disabled={!isEditing || !editable}
                                >
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Form.Control>
                            ) : (
                                <Form.Control
                                    as={as || 'input'}
                                    rows={rows || 1}
                                    name={key}
                                    value={value ?? editableSeller[key] ?? ''}
                                    readOnly={!isEditing || !editable}
                                    onChange={editable ? handleInputChange : undefined}
                                    isInvalid={validationError && key === 'collectionCostPerKilo'}
                                />
                            )}
                            {validationError && key === 'collectionCostPerKilo' && (
                                <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                ))}
            </Row>
        );
    };

    return (
        <Container className="content">
            <h1>Seller Details</h1>
            <div className="d-flex justify-content-between mb-3">
                <Button variant="secondary" onClick={handleBack} disabled={isSaving}>
                    <FaArrowLeft className="me-2 mb-1" />
                    Back
                </Button>
                <div>
                    {isEditing ? (
                        <>
                            <Button variant="danger" onClick={handleCancel} disabled={isSaving} className="me-3">
                                <FaTimes className="me-2 mb-1" />
                                Cancel
                            </Button>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        {!hasChanges ? 'No changes detected' : 'Save changes'}
                                    </Tooltip>
                                }
                            >
                                <span className="d-inline-block">
                                    <Button
                                        variant="primary"
                                        onClick={handleShowModal}
                                        disabled={!hasChanges || isSaving}
                                    >
                                        <FaSave className="me-2 mb-1" />
                                        Save
                                    </Button>
                                </span>
                            </OverlayTrigger>
                        </>
                    ) : (
                        <Button variant="secondary" onClick={handleEdit} disabled={isSaving}>
                            <FaEdit className="me-2 mb-1" />
                            Edit
                        </Button>
                    )}
                </div>
            </div>
            {isSaved && <Alert variant="success">Record has been saved</Alert>}
            {saveError && <Alert variant="danger">{saveError}</Alert>}

            <Card className="mb-3">
                <Card.Header>Basic Information</Card.Header>
                <Card.Body>
                    <Form>{renderFields(basicInformation)}</Form>
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>Images</Card.Header>
                <Card.Body>{renderImages()}</Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>Content</Card.Header>
                <Card.Body>
                    <Form>{renderFields(contentInformation)}</Form>
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>Audit Information</Card.Header>
                <Card.Body>
                    <Form>{renderFields(auditFields)}</Form>
                </Card.Body>
            </Card>

            {/* Confirmation Modal */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Save</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Your changes will be reflected in the production apps immediately.</p>
                    <p>Are you sure you wish to save the new details?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={imageModal.show} onHide={closeImageModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Image
                        src={`${baseImageUrl}${editableSeller.sellerImages[imageModal.currentIndex]}`}
                        alt="Seller Image"
                        fluid
                    />
                    <div style={{ marginTop: '1rem', fontSize: '0.9rem', wordBreak: 'break-all' }}>
                        {`${baseImageUrl}${editableSeller.sellerImages[imageModal.currentIndex]}`}
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={goToPreviousImage}>
                        <FaChevronLeft />
                    </Button>
                    <Button variant="secondary" onClick={goToNextImage}>
                        <FaChevronRight />
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
};

export default SellerDetails;
