import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Form, Alert, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';
import { FaArrowLeft, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import '../../styles.css';
import { getSellerCodes } from '../../services/sellerService'; // Import the sellerService
import UserCompaniesTable from './CompaniesTable'

const UserDetails = () => {
    const [user, setUser] = useState(null);
    const [editableUser, setEditableUser] = useState({});
    const [backupUser, setBackupUser] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [sellerOptions, setSellerOptions] = useState([]); // Store seller dropdown options
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const query = new URLSearchParams(location.search);
            const userId = query.get('userId');

            if (!userId) {
                console.error('No userId found in URL');
                setIsLoading(false);
                return;
            }

            try {
                // Fetch user
                const getUserById = httpsCallable(functions, 'getUserById');
                const response = await getUserById({ userId });
                const userData = response.data.user;

                // Convert Firestore Timestamp to Date
                if (userData.createdAt && userData.createdAt.toDate) {
                    userData.createdAt = userData.createdAt.toDate();
                }
                if (userData.updatedAt && userData.updatedAt.toDate) {
                    userData.updatedAt = userData.updatedAt.toDate();
                }

                setUser(userData);
                setEditableUser(userData);

                // Fetch seller codes
                const sellers = await getSellerCodes();
                const options = sellers.map((seller) => ({
                    value: seller.sellerCode,
                    label: `${seller.sellerName} (${seller.sellerCode})`,
                }));
                setSellerOptions(options);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [location]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditableUser({ ...editableUser, [name]: value });
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setEditableUser({ ...editableUser, [name]: selectedOption ? selectedOption.value : '' });
    };

    const handleEdit = () => {
        setBackupUser(editableUser);
        setIsEditing(true);
    };

    const handleCancel = () => {
        setEditableUser(backupUser);
        setIsEditing(false);
    };

    const handleSave = async () => {
        setIsSaving(true);
        setIsSaved(false);
        setSaveError(null);
        try {
            const updateUser = httpsCallable(functions, 'updateUser');
            await updateUser({
                email: editableUser.email,
                userId: editableUser.userId,
                firstName: editableUser.firstName,
                lastName: editableUser.lastName,
                displayName: editableUser.displayName,
                phoneNumber: editableUser.phoneNumber,
                role: editableUser.role,
                jobTitle: editableUser.jobTitle,
                sellerCode: editableUser.sellerCode,
                disabled: editableUser.disabled, // Send the account status field
            });
            setIsSaved(true);
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving user:', error);
            setSaveError(error.message + ": " + error.details); // Store the error message
        } finally {
            setIsSaving(false);
        }
    };

    const handleBack = () => {
        navigate('/users');
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (!user) {
        return <p>Loading...</p>;
    }

    const formatDateTime = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour12: false,
        });
    };

    const roleOptions = [
        { value: 'buyer', label: 'Buyer' },
        { value: 'seller', label: 'Seller' },
        { value: 'sellerAdmin', label: 'Seller Admin' },
        { value: 'company', label: 'Company' },
        { value: 'companyAdmin', label: 'Company Admin' },
        { value: 'hsAdmin', label: 'HS Admin' },
    ];

    const statusOptions = [
        { value: false, label: 'Active' },
        { value: true, label: 'Suspended' },
    ];

    const basicInformation = [
        { label: 'First Name', key: 'firstName', type: 'text' },
        { label: 'Last Name', key: 'lastName', type: 'text' },
        { label: 'Display Name', key: 'displayName', type: 'text' },
        { label: 'Email', key: 'email', type: 'email', readOnly: true },
        { label: 'Phone Number', key: 'phoneNumber', type: 'text' },
        { label: 'Role', key: 'role', type: 'select', options: roleOptions },
        { label: 'Job Title', key: 'jobTitle', type: 'text' },
        { label: 'Account Status', key: 'disabled', type: 'select', options: statusOptions },
        { label: 'Seller Code', key: 'sellerCode', type: 'select', options: sellerOptions }, // Updated to dropdown
    ];

    const auditFields = [
        { label: 'User ID', key: 'userId', readOnly: true },
        { label: 'Created', key: 'created', readOnly: true },
        { label: 'Last Login', key: 'lastLogin', readOnly: true },
        { label: 'Last Updated', key: 'updatedAt', readOnly: true },
    ];

    const renderFields = (fields) => {
        return (
            <Row>
                {fields.map(({ label, key, type, options, readOnly }) => (
                    <Col md={6} key={key} className="mb-3">
                        <Form.Group>
                            <Form.Label>{label}</Form.Label>
                            {type === 'select' ? (
                                <Select
                                    name={key}
                                    options={options}
                                    onChange={handleSelectChange}
                                    value={options.find(option => option.value === editableUser[key]) || null}
                                    isDisabled={readOnly || !isEditing || isSaving}
                                />
                            ) : (
                                <Form.Control
                                    type={type}
                                    name={key}
                                    value={key === 'createdAt' || key === 'updatedAt' || key === 'lastLogin' || key === 'created' ? formatDateTime(editableUser[key]) : editableUser[key] ?? ''}
                                    readOnly={readOnly || !isEditing || isSaving}
                                    onChange={handleInputChange}
                                />
                            )}
                        </Form.Group>
                    </Col>
                ))}
            </Row>
        );
    };

    return (
        <Container className="content">
            <h1>User Details</h1>
            <div className="d-flex justify-content-between mb-3">
                <Button variant="secondary" onClick={handleBack} disabled={isSaving}>
                    <FaArrowLeft className="me-2 mb-1" />
                    Back
                </Button>
                <div>
                    {isEditing ? (
                        <>
                            <Button variant="danger" onClick={handleCancel} disabled={isSaving} className="me-3">
                                <FaTimes className="me-2 mb-1" />
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSave}
                                disabled={isSaving}
                            >
                                {isSaving ? (
                                    <span className="spinner-border spinner-border-sm"></span>
                                ) : (
                                    <>
                                        <FaSave className="me-2 mb-1" />
                                        Save
                                    </>
                                )}
                            </Button>
                        </>
                    ) : (
                        <Button variant="secondary" onClick={handleEdit} disabled={isSaving}>
                            <FaEdit className="me-2 mb-1" />
                            Edit
                        </Button>
                    )}
                </div>
            </div>
            {isSaved && <Alert variant="success">Record has been saved</Alert>}
            {saveError && <Alert variant="danger">{saveError}</Alert>} {/* Display error message */}
            {[
                { title: 'Basic Information', fields: basicInformation },
                { title: 'Audit Fields', fields: auditFields },
            ].map((section, idx) => (
                <Card key={idx} className="mb-3">
                    <Card.Header>{section.title}</Card.Header>
                    <Card.Body>
                        <Form>{renderFields(section.fields)}</Form>
                    </Card.Body>
                </Card>
            ))}
            <Card className="mb-3">
                <Card.Header>Associated Companies</Card.Header>
                <Card.Body>
                    <UserCompaniesTable />
                </Card.Body>
            </Card>
        </Container>
    );
};

export default UserDetails;
