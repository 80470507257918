import React, { useState } from 'react';
import { Modal, Table, Button, FormControl, Alert, Spinner } from 'react-bootstrap';

const SkuSelectionModal = ({ show, handleClose, skus, handleSkuSelect, error }) => {
  const [quantities, setQuantities] = useState({});
  const [saving, setSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const [disableSelection, setDisableSelection] = useState(false);

  // Handle quantity input change
  const handleQuantityChange = (skuId, value) => {
    setQuantities({ ...quantities, [skuId]: value });
  };

  // Handle selecting a SKU
  const onSelectSku = async (item, quantity) => {
    setSaving(true);
    setDisableSelection(true);

    try {
      await handleSkuSelect(item, quantity);
      setSaveStatus({ type: 'success', text: 'Selection updated successfully!' });
    } catch (err) {
      setSaveStatus({ type: 'danger', text: 'Failed to update selection. Please try again.' });
    } finally {
      setSaving(false);
    }
  };

  return (
<Modal show={show} onHide={handleClose} centered size={saving || saveStatus ? "sm" : "xl"}>
  <Modal.Header closeButton>
    <Modal.Title>{saving || saveStatus ? "Info" : "Select a SKU"}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {error && <Alert variant="danger">{error}</Alert>}

    {saving || saveStatus ? (
      <div className="text-center">
        {saving ? (
          <>
            <Spinner animation="border" role="status" />
            <p className="mt-2">Saving selection...</p>
          </>
        ) : (
          <>
            <Alert variant={saveStatus.type}>{saveStatus.text}</Alert>
            <Button variant="primary" className="w-100 mt-2" onClick={() => window.location.reload()}>
              OK
            </Button>
          </>
        )}
      </div>
    ) : (
      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover responsive style={{ fontSize: '10pt', width: '100%' }}>
          <thead>
            <tr>
              <th>SKU ID</th>
              <th>Name</th>
              <th>Cut</th>
              <th>Pack Format</th>
              <th>Category</th>
              <th>Unit Size Name</th>
              <th>Product Size Measure</th>
              <th>Pack Type</th>
              <th>Process</th>
              <th>Price</th>
              <th>UOM</th>
              <th>Quantity</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {skus.map((item) => (
              <tr key={item.skuId}>
                <td>{item.skuId}</td>
                <td>{item.skuName}</td>
                <td>{item.cut}</td>
                <td>{item.packFormat}</td>
                <td>{item.category}</td>
                <td>{item.unitSizeName}</td>
                <td>{item.productSizeMeasure}</td>
                <td>{item.packType}</td>
                <td>{item.process}</td>
                <td>{item.price}</td>
                <td>{item.uom}</td>
                <td>
                  <FormControl
                    type="number"
                    placeholder="Qty"
                    value={quantities[item.skuId] || ''}
                    onChange={(e) => handleQuantityChange(item.skuId, e.target.value)}
                    min="0"
                  />
                </td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => onSelectSku(item, quantities[item.skuId])}
                    disabled={!quantities[item.skuId] || parseFloat(quantities[item.skuId]) <= 0 || disableSelection}
                  >
                    Select
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )}
  </Modal.Body>
  <Modal.Footer>
    {!saveStatus && (
      <Button variant="secondary" onClick={handleClose} disabled={saving}>
        Close
      </Button>
    )}
  </Modal.Footer>
</Modal>

  );
};

export default SkuSelectionModal;
