import React, { useEffect, useState } from 'react';
import { Table, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getUserCompanies } from '../../services/userService';

const UserCompaniesTable = () => {
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // React Router navigation hook

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await getUserCompanies();

                if (response.success) {
                    setCompanies(response.companies);
                } else {
                    throw new Error('Failed to retrieve companies.');
                }
            } catch (error) {
                console.error('Error fetching user companies:', error);
                setError('Failed to fetch companies. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchCompanies();
    }, []);

    const handleRowClick = (companyId) => {
        navigate(`/buyer-details?companyId=${companyId}`);
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading companies...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    if (companies.length === 0) {
        return <Alert variant="warning">No companies found.</Alert>;
    }

    return (
        <Table striped bordered hover responsive className="clickable-table">
            <thead>
                <tr>
                    <th>Company Name</th>
                    <th>Trading Name</th>
                    <th>Company ID</th>
                </tr>
            </thead>
            <tbody>
                {companies.map((company) => (
                    <tr key={company.companyId} onClick={() => handleRowClick(company.companyId)} style={{ cursor: 'pointer' }}>
                        <td>{company.companyName}</td>
                        <td>{company.tradingName}</td>
                        <td>{company.companyId}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default UserCompaniesTable;
